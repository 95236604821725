<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.995 511.995"
    style="enable-background:new 0 0 511.995 511.995;"
    xml:space="preserve"
  >
    <path
      style="fill:#A0DBFD;"
      d="M383.332,115.163V61.874H128.665v53.289c0,58.584,39.566,107.922,93.434,122.763
	c8.113,2.235,13.702,9.659,13.702,18.074l0,0c0,8.415-5.589,15.839-13.702,18.074c-53.867,14.841-93.434,64.178-93.434,122.763
	v53.289h254.666v-53.289c0-58.584-39.566-107.922-93.432-122.763c-8.113-2.235-13.702-9.659-13.702-18.074l0,0
	c0-8.415,5.589-15.839,13.702-18.074C343.766,223.085,383.332,173.747,383.332,115.163z"
    />
    <path
      style="fill:#96D5F6;"
      d="M244.205,256.001c0,4.204-0.811,8.171-2.233,11.364c-1.397,3.181-3.406,5.589-5.776,6.711
	c-31.448,14.832-54.551,64.182-54.551,122.763v53.291h-52.98V396.84c0-58.581,39.569-107.93,93.435-122.763
	c4.054-1.123,7.472-3.53,9.892-6.711c2.408-3.193,3.805-7.16,3.805-11.364c0-8.42-5.589-15.843-13.697-18.076
	c-53.865-14.845-93.435-64.182-93.435-122.763V61.872h52.98v53.291c0,58.581,23.103,107.918,54.551,122.763
	C240.937,240.159,244.205,247.581,244.205,256.001z"
    />
    <path
      style="fill:#C1E9FD;"
      d="M279.995,244.637c-2.408,3.193-3.805,7.16-3.805,11.364c0,8.42,5.589,15.843,13.71,18.076
	c53.865,14.832,93.435,64.182,93.435,122.763v53.291h-52.98V396.84c0-58.581-23.103-107.93-54.564-122.763
	c-4.74-2.233-7.996-9.655-7.996-18.076c0-4.204,0.811-8.171,2.22-11.364c1.41-3.181,3.406-5.589,5.776-6.711
	c31.461-14.845,54.564-64.182,54.564-122.763V61.872h52.98v53.291c0,58.581-39.569,107.918-93.435,122.763
	C285.833,239.048,282.415,241.456,279.995,244.637z"
    />
    <path
      style="fill:#FED159;"
      d="M256.002,129.793c-24.987,0-49.961-3.218-74.311-9.63c-5.651-1.497-11.277-3.169-16.853-5.002
	c0,24.974,10.242,48.164,27.182,64.905c10.803,10.691,24.338,18.762,39.682,22.978c9.431,2.607,17.726,7.61,24.301,14.234
	c6.574-6.624,14.857-11.626,24.288-14.234c39.37-10.84,66.864-46.979,66.864-87.884
	C317.552,124.916,286.777,129.793,256.002,129.793z M256.002,332.318l-74.361,66.589l-16.803,15.044h182.316L256.002,332.318z"
    />
    <rect x="95.986" style="fill:#65B4BB;" width="320.023" height="63.121" />
    <rect x="95.986" style="fill:#57A3A7;" width="50.575" height="63.121" />
    <rect
      x="95.986"
      y="448.874"
      style="fill:#65B4BB;"
      width="320.023"
      height="63.121"
    />
    <rect
      x="95.986"
      y="448.874"
      style="fill:#57A3A7;"
      width="50.575"
      height="63.121"
    />
    <g>
      <polygon
        style="fill:#F6C454;"
        points="164.838,413.952 181.641,398.908 181.641,413.952 	"
      />
      <path
        style="fill:#F6C454;"
        d="M192.02,180.066c-16.941-16.741-27.182-39.931-27.182-64.905c5.576,1.834,11.202,3.505,16.853,5.002
		C182.19,141.993,185.895,162.402,192.02,180.066z"
      />
    </g>
    <g>
      <polygon
        style="fill:#FFE4A9;"
        points="347.154,413.952 330.351,398.908 330.351,413.952 	"
      />
      <path
        style="fill:#FFE4A9;"
        d="M319.972,180.066c16.94-16.741,27.182-39.931,27.182-64.905c-5.576,1.834-11.202,3.505-16.853,5.002
		C329.802,141.993,326.097,162.402,319.972,180.066z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
